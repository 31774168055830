import { render, staticRenderFns } from "./DetailTransaksi.vue?vue&type=template&id=0d333031&scoped=true"
import script from "./DetailTransaksi.vue?vue&type=script&lang=js"
export * from "./DetailTransaksi.vue?vue&type=script&lang=js"
import style0 from "./DetailTransaksi.vue?vue&type=style&index=0&id=0d333031&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d333031",
  null
  
)

export default component.exports