import { render, staticRenderFns } from "./SkeletonSlider.vue?vue&type=template&id=25bc3e9a&scoped=true"
var script = {}
import style0 from "./SkeletonSlider.vue?vue&type=style&index=0&id=25bc3e9a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25bc3e9a",
  null
  
)

export default component.exports